.swiper-button-prev svg,
.swiper-button-next svg {
  width: 16px !important;
}

.actioonBtn2 {
  border-radius: 5px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.bgPending {
  background-color: var(--bs-warning) !important;
  color: var(--bs-neutral-900) !important;
}

.bgAprved {
  background-color: var(--bs-background-6) !important;
  color: var(--bs-neutral-900) !important;
}

.bgOn {
  background-color: var(--bs-success) !important;
  color: var(--bs-neutral-900) !important;
}

.bgCompleted {
  background-color: var(--bs-neutral-300) !important;
  color: var(--bs-neutral-900) !important;
}

.bgCancelled {
  background-color: var(--bs-danger) !important;
  color: var(--bs-background-white) !important;
}

@media screen and (min-width: 768px) {
  .newFlex {
    display: flex;
    justify-content: center;
  }
}

.sticky0 {
  position: sticky !important;
  top: 0 !important;
}

.bookingEngineColumn {
  position: sticky;
  top: 86px;
  height: fit-content;
}

footer {
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  .activebtncontqact {
    background-color: var(--primary-color) !important;
  }
}

@media screen and (max-width: 576px) and (min-width: 380px) {
  .widthWIthin {
    width: 65%;
  }
}

@media screen and (min-width: 768px) {
  .newWIdth {
    width: 25% !important;
  }
}

.borderTopNew {
  border-top: 5px solid var(--primary-color);
}

.paymentCards1 {
  width: calc(100% / 5);
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
  .paymentCards1 {
    width: calc(100% / 3);
  }
}