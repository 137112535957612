.profile_img_section {

    .profile-image-container {
        display: flex;
        /* justify-content: center; */
        margin-bottom: 24px;
    }

    .profile-image-upload {
        position: relative;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        border: 2px dashed #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
        transition: border-color 0.2s;
    }

    .profile-image-upload:hover {
        border-color: #4ade80;
    }

    .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #6b7280;
    }

    .upload-text {
        font-size: 12px;
        margin-top: 8px;
    }

    .hidden {
        display: none;
    }



}


.error-text {
    color: red;
    font-size: 13px;
}

.MargnClssCndiomn {
    margin-bottom: 0px;

}

.success-modal-content {
    max-width: 400px;
    margin: 0 auto;
}

.success-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.success-icon {
    height: 64px;
    width: 64px;
    color: #22c55e;
    margin-bottom: 16px;
}

.success-text {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
}

.paschngertxt,
.paschngertxt2 {
    font-size: 20px !important;
}

.paschngertxt2 {
    font-size: 22px !important;
}


@media screen and (max-width: 768px) {
    .profile-image-container {
        justify-content: center;
    }
}